<template>
  <div>
    <form-section :title="$t('courierSet.shipmentMethod.title')">
      <v-row class="mb-4">
        <v-col>
          <v-text-field
            v-model="getConfigurationValues.configurationName"
            :label="`${$t('courierSet.shipmentMethod.configurationName')}*`"
            :rules="[required]"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="getConfigurationValues.id"
            :label="$t('courierSet.shipmentMethod.configurationId')"
            outlined
            dense
            hide-details
            disabled
          />
        </v-col>
      </v-row>
      <v-combobox
        :value="getConfigurationValues.courierCode"
        :items="SHIPMENT_METHOD_CODES_SHORT"
        :label="`${$t('courierSet.deliverySpeditor2')}*`"
        :rules="[required]"
        class="my-4"
        item-text="name"
        outlined
        hide-details
        @change="getConfigurationValues.courierCode = $event"
      >
        <template v-slot:selection="{ item }">
          <span v-if="SHIPMENT_METHOD_CODES_SHORT.includes(item)">
            {{ $t(`speditors.${item.toLowerCase()}`) }}
          </span>
          <span v-else> {{ item }}</span>
        </template>
        <template v-slot:item="{ item }">
          {{ $t(`speditors.${item.toLowerCase()}`) }}
        </template>
      </v-combobox>

      <v-checkbox
        v-model="getConfigurationValues.requireLabel"
        hide-details
        color="primary lighten-1"
        class="rounded pa-2 mt-4"
        :class="getConfigurationValues.requireLabel && 'primary lighten-4'"
      >
        <template v-slot:label>
          <span
            :class="getConfigurationValues.requireLabel ? 'text--primary-lighten1' : 'text--black'"
            >{{ $t('courierSet.realizeByExternalLP') }}</span
          >
        </template>
      </v-checkbox>
    </form-section>
  </div>
</template>

<script>
import Vue from 'vue';
import { deliveryMethods } from '@/constants/Speditors/PocztaPolska/SendingMethods';
import { services } from '@/constants/Speditors/PocztaPolska/Services';
import { mapGetters, mapMutations } from 'vuex';
import { SHIPMENT_METHOD_CODES_SHORT } from '@/constants/ShipmentMethodCodes.js';
import { required } from '@/rules/rules.js';

export default Vue.extend({
  props: {
    isNew: { type: Boolean, default: false },
  },

  data: () => ({
    SHIPMENT_METHOD_CODES_SHORT,
    deliveryMethods,
    services,
    channelItems: [
      { id: 1, name: 'WEB_API' },
      { id: 2, name: 'MS' },
      { id: 3, name: 'REST_API ' },
    ],
  }),

  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),

    getConfigurationValues() {
      return this.getConfigurationSet();
    },
  },
  methods: {
    ...mapMutations('spedition', ['createConfigSet']),

    required,
  },
});
</script>

<style scoped></style>
